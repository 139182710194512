import styled from 'styled-components'

export const CredentialsWrapper = styled.div`
  margin: 25px 50px;
  @media screen and (min-width: 320px) and (max-width: 480px) {
    margin: 25px 20px;
  }

  .forgot-pwd-btn {
    border: 0;
    outline: 0;
    color: #ff7200;
    background-color: transparent;
    text-decoration: underline;
    font-size: 1rem;
  }
  .tab-child__heading {
    display: none;
  }
  .tab-container {
    width: 500px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

export const SecretKeySectionStyles = styled.div`
  .secret-key-section {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #202020;
  }
  .keys-modal {
    ul {
      list-style-position: inside;
    }
    .key-wrapper {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      padding: 1rem;
      border-radius: 4px;
      .key {
        word-break: break-all;
        color: #536471;
      }
      .copy-btn {
        background: transparent;
        border: 0;
        color: #009fb7;
        font-weight: 600;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .app-pri-btn {
      padding: 0.7rem 2rem;
    }
  }
  .generate-key-btn {
    font-size: 1rem;
    padding: 0.65rem 2.5rem;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .generate-key-btn:hover {
    background-color: rgb(178, 79, 0);
  }
`

export const PersonalInfoWrapper = styled.div`
  .pencil-icon {
    fill: #fff;
  }
  .app-pri-color {
    color: #ff7200;
  }
  .profile-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
  }
  .phone-no__input {
    flex: 3;
    width: 100%;
    padding-right: 10px;
    input {
      font-size: 1rem;
      padding: 16.5px 14px;
    }
  }
  .otp-inp {
    display: inline-block;
    margin: 0px auto;
    border: none;
    padding: 0px;
    width: 9ch;
    background: repeating-linear-gradient(
        90deg,
        black 0px,
        black 1ch,
        transparent 0px,
        transparent 1.5ch
      )
      0px 100% / 10ch 2px no-repeat;
    letter-spacing: 0.5ch;
    font: 2rem 'Ubuntu Mono', monospace !important;
  }
  .otp-inp:focus {
    outline: none;
    color: black;
  }
  .otp-inp::-webkit-outer-spin-button,
  .otp-inp::-webkit-inner-spin-button {
    display: none;
  }
`
export const BillingTabStyles = styled.div`
  min-height: 60vh;

  .btn-wrappper .cancel-sub-btn {
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 10px 30px;
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .cancel-subscription-modal {
    .heading {
      font-size: 1.5rem;
    }
    .close-icon {
      width: 20px;
      height: 20px;
    }
    .btn-container {
      button {
        font-size: 1rem;
      }
      .app-pri-btn {
        padding: 10px 30px;
      }
      .app-sec-btn {
        color: #000000;
      }
    }
  }
`
