import React, { useState } from 'react'
import { useFormik } from 'formik'
import {
  Box,
  Typography,
  OutlinedInput,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { changePassword, confirmEmailForgotPwd } from '../../redux/auth'
import { useDispatch } from 'react-redux'
import { errorToast, successToast } from '../toasts'
import { useSelector } from 'react-redux'
import { Loader, Modal } from 'esstart-components'
import { SecretKeySectionStyles } from './style'
import { generateSecretKey } from '../../redux/settings'
import copyIcon from '../../assets/images/copy-icon.svg'

const PASSWORD_CRITERIA_MESSAGE = 'Please enter the password'
const PASSWORD_MANDATORY_MESSAGE =
  'Please match the criteria. (8 characters with Numbers, Special Character, Caps and Small Letter)'
const SAME_NEW_PASSWORD_MESSAGE = 'Passwords must be same'

interface IPasswordChange {
  previous_password: string
  new_password: string
  confirm_new_password: string
}

const passwordValidation = (values: any) => {
  // const passwordPattern =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-])[a-zA-Z0-9\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-]{8,98}$/

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/

  const errors = {} as IPasswordChange

  if (!values?.previous_password) {
    errors.previous_password = PASSWORD_MANDATORY_MESSAGE
  } else if (!passwordPattern.test(values.previous_password)) {
    errors.previous_password = PASSWORD_CRITERIA_MESSAGE
  }
  if (!values?.new_password) {
    errors.new_password = PASSWORD_MANDATORY_MESSAGE
  } else if (!passwordPattern.test(values.new_password)) {
    errors.new_password = PASSWORD_CRITERIA_MESSAGE
  }
  if (!values?.confirm_new_password) {
    errors.confirm_new_password = PASSWORD_MANDATORY_MESSAGE
  } else if (!passwordPattern.test(values.confirm_new_password)) {
    errors.confirm_new_password = PASSWORD_CRITERIA_MESSAGE
  }
  if (
    !!values?.new_password &&
    !!values?.confirm_new_password &&
    values?.new_password !== values?.confirm_new_password
  ) {
    errors.new_password = SAME_NEW_PASSWORD_MESSAGE
    errors.confirm_new_password = SAME_NEW_PASSWORD_MESSAGE
  }
  return errors
}

const CredentialsTab = () => {
  const dispatch = useDispatch<any>()
  const auth = useSelector((store: any) => store?.auth)
  const settings = useSelector((store: any) => store?.settings)

  const [showKeysModal, toggleShowKeysModal] = useState(false)

  const formik = useFormik({
    initialValues: {
      previous_password: '',
      new_password: '',
      confirm_new_password: '',
      showPasswordId: '',
    },
    validate: passwordValidation,
    onSubmit: async (values: any) => {
      const res = await dispatch(
        changePassword({ ...values, showPasswordId: undefined })
      )
      if (res?.payload?.status === 'success') {
        successToast('Password changed successfully !')
      } else {
        errorToast('Something went wrong !')
      }
      formik.resetForm()
    },
  })

  const handleClickShowPassword = (id: string) => () => {
    formik.setFieldValue('showPasswordId', id)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const invokeVerifyEmailApi = async () => {
    const res = await dispatch(
      confirmEmailForgotPwd({ username: auth?.username })
    )
    if (res?.payload?.status === 'success') {
      successToast('Reset password instructions are sent to your email.')
    } else {
      errorToast('Something went wrong !')
    }
  }

  const generateSecretKeyClickHandler = async () => {
    const res = await dispatch(generateSecretKey())
    if (res?.payload?.data?.publicKey) {
      toggleShowKeysModal(true)
    } else {
      errorToast('Something went wrong!')
    }
  }

  const copiedNotification = (key: string) => {
    navigator.clipboard.writeText(key)
    successToast('Copied!')
  }

  return (
    <>
      <div
        style={{
          display: auth?.loading || settings?.loading ? 'flex' : 'none',
        }}
      >
        <Loader />
      </div>
      <Box sx={{ marginTop: '20px', maxWidth: '767px' }}>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <FormControl fullWidth sx={{ marginY: '15px' }}>
              <Typography>Enter Current Password</Typography>
              <OutlinedInput
                id="previous_password"
                placeholder="Enter current password"
                type={
                  formik.values.showPasswordId === 'previous_password'
                    ? 'text'
                    : 'password'
                }
                value={formik.values.previous_password}
                name="previous_password"
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      name="previous_password"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formik.values.showPasswordId === 'previous_password' ? (
                        <VisibilityOff onClick={handleClickShowPassword('')} />
                      ) : (
                        <Visibility
                          onClick={handleClickShowPassword('previous_password')}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.errors.previous_password &&
              formik.touched.previous_password ? (
                <small className="errorMsg">
                  {formik.errors.previous_password as string}
                </small>
              ) : null}
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth sx={{ marginY: '15px' }}>
              <Typography>Enter New Password</Typography>
              <OutlinedInput
                id="new_password"
                name="new_password"
                placeholder="Enter new password"
                type={
                  formik.values.showPasswordId === 'new_password'
                    ? 'text'
                    : 'password'
                }
                value={formik.values.new_password}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      name="new_password"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formik.values.showPasswordId === 'new_password' ? (
                        <VisibilityOff onClick={handleClickShowPassword('')} />
                      ) : (
                        <Visibility
                          onClick={handleClickShowPassword('new_password')}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.errors.new_password && formik.touched.new_password ? (
                <small className="errorMsg">
                  {formik.errors.new_password as string}
                </small>
              ) : null}
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth sx={{ marginY: '15px' }}>
              <Typography>Confirm New Password</Typography>
              <OutlinedInput
                id="confirm_new_password"
                name="confirm_new_password"
                placeholder="Enter password again"
                type={
                  formik.values.showPasswordId === 'confirm_new_password'
                    ? 'text'
                    : 'password'
                }
                value={formik.values.confirm_new_password}
                onChange={formik.handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      name="confirm_new_password"
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {formik.values.showPasswordId ===
                      'confirm_new_password' ? (
                        <VisibilityOff onClick={handleClickShowPassword('')} />
                      ) : (
                        <Visibility
                          onClick={handleClickShowPassword(
                            'confirm_new_password'
                          )}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.errors.confirm_new_password &&
              formik.touched.confirm_new_password ? (
                <small className="errorMsg">
                  {formik.errors.confirm_new_password as string}
                </small>
              ) : null}
            </FormControl>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={{
                borderRadius: '25px',
                color: '#fff',
                width: '150px',
                height: '40px',
              }}
            >
              CHANGE
            </Button>
          </Box>
        </form>
        <button
          className="forgot-pwd-btn cursor-pointer mt-5"
          onClick={invokeVerifyEmailApi}
        >
          Forgot Current Password?
        </button>
      </Box>

      <SecretKeySectionStyles>
        <div className="secret-key-section">
          {showKeysModal && (
            <Modal size="xl" open={true} className="keys-modal">
              <div className="d-flex flex-column gap-5">
                <h2>Save your Public Key and Secret Key</h2>
                <div>
                  <ul>
                    <li>Save them in a secure location</li>
                    <li>Treat them like a password or a set of keys</li>
                    <li>If security has been compromised, regenerate them</li>
                    <li>DO NOT store them in public places or shared docs</li>
                  </ul>
                </div>
                <div className="d-flex flex-column gap-4">
                  <div>
                    <p>Public Key</p>
                    <div className="d-flex gap-4 align-items-center justify-content-between key-wrapper">
                      <p className="key">{settings?.securityKeys?.publicKey}</p>
                      <button
                        className="copy-btn d-flex align-items-center gap-1 cursor-pointer"
                        onClick={() =>
                          copiedNotification(settings?.securityKeys?.publicKey)
                        }
                      >
                        <img src={copyIcon} alt="copy" />
                        <span>Copy</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>Secret Key</p>
                    <div className="d-flex gap-4 align-items-center justify-content-between key-wrapper">
                      <p className="key">{settings?.securityKeys?.secretKey}</p>
                      <button
                        className="copy-btn d-flex align-items-center gap-1 cursor-pointer"
                        onClick={() =>
                          copiedNotification(settings?.securityKeys?.secretKey)
                        }
                      >
                        <img src={copyIcon} alt="copy" />
                        <span>Copy</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="app-pri-btn"
                    onClick={() => toggleShowKeysModal(false)}
                  >
                    Yes, I saved them
                  </button>
                </div>
              </div>
            </Modal>
          )}

          <p>
            Click below to generate keys for connecting your Esstart account
            with other platform
          </p>
          <button
            className="generate-key-btn app-pri-btn mt-2"
            onClick={generateSecretKeyClickHandler}
          >
            GENERATE
          </button>
        </div>
      </SecretKeySectionStyles>
    </>
  )
}

export default CredentialsTab
