import { useEffect } from 'react'
import { TradeshowPageStyles, WinnersTabStyles } from './style'
import { RenderImage, TableCard } from '.'
import { useSelector, useDispatch } from 'react-redux'
import { getTradeshowUsers } from '../../redux/tradeshow'

export const TradeshowWinners = () => {
  const dispatch: any = useDispatch()
  const tradeshowData = useSelector((state: any) => state?.tradeshowData)

  useEffect(() => {
    dispatch(getTradeshowUsers())
  }, [])

  const headers = [
    { name: 'Name', keyName: 'name', showOnDesktop: true, showOnMobile: true },
    {
      name: 'Business Type',
      keyName: 'businessType',
      showOnDesktop: true,
      showOnMobile: true,
    },
    {
      name: 'Photo',
      keyName: 'photo',
      showOnDesktop: true,
      showOnMobile: false,
    },
  ]

  const rows = tradeshowData?.winners?.map((item: any) => ({
    name: item.name || '',
    businessType: item.businessType || '',
    photo: item?.imgS3Key ? <RenderImage imgS3Key={item?.imgS3Key} /> : <></>,
  }))

  const data = {
    headers,
    rows,
  }
  return (
    <TradeshowPageStyles>
      <WinnersTabStyles>
        <div className="mt-4">
          <div className="mb-4">
            <h2>Tradeshow Winners ({tradeshowData?.winners?.length || '0'})</h2>
          </div>
          <TableCard data={data} />
        </div>
      </WinnersTabStyles>
    </TradeshowPageStyles>
  )
}
